$(window).on('load', () => {

  // block scroll animation
  let animationBlocks = document.querySelectorAll('.aos-animation-block');
  animationBlocks.forEach(function(block) {
    block.setAttribute('data-aos', 'fade-up');
  });

  AOS.init({
    offset: jQuery(window).height() * 0.1,
    duration: 700,
    delay: 200,
  });
  const header = document.querySelector('header');
  // const submenu = document.querySelectorAll('.sub-menu');
  // // var dropdown = document.querySelector('.dropdown');

  window.addEventListener('scroll', function (e) {
    let scrollPosition = window.scrollY;
    if (+scrollPosition > 40) {
      if (!header.classList.contains('scrolled')) {
        header.classList.add('scrolled');
      }
    } else {
      header.classList.remove('scrolled');
    }
  });

  $('#mainHamburger').on('click', function(e) {
    $('#mobileMenu').toggleClass('active');
    $('#mainHamburger').toggleClass('active');
    $('body').toggleClass('mm-active');

    if (!header.classList.contains('scrolled') && !header.classList.contains('header--transparent')) {
      header.classList.toggle('shadow');
    }
  })

  $('#mobileMenu a').on('click', function() {
    $('#mobileMenu').removeClass('active');
    $('#mainHamburger').removeClass('active');
    $('body').toggleClass('mm-active');
  })

  $('.nav-mobile .show-submenu').on('click', function() {
    $(this).parent('.menu-item').toggleClass('active');
    $(this).next().slideToggle();
  })


});

$( window ).resize(function() {
  if ($(window).width() > 1050) {
    $('#mobileMenu').removeClass('active');
    $('#mainHamburger').removeClass('active');
    $('body').removeClass('mm-active');
  }
});